// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyDQUbodIUq0ZyUZjPAyxjL5WtqxJAGOdIg",
    authDomain: "sharecheck-c3b5f.firebaseapp.com",
    projectId: "sharecheck-c3b5f",
    storageBucket: "sharecheck-c3b5f.appspot.com",
    messagingSenderId: "787089034708",
    appId: "1:787089034708:web:ae2218e72138ffecba9a41",
    measurementId: "G-JRP3J84C0F"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
