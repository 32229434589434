import { Injectable ,Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { ApiService } from '../service/api.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private injector:Injector) {}

  intercept(req:any,next:any){
    let apiService=this.injector.get(ApiService);
    let tokenizedReq= req.clone({
      setHeaders:{
        Authorization: `Bearer ${apiService.getTokendata()}`
      }
    })
    return next.handle(tokenizedReq)
  }
}
