import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators,FormBuilder, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  userForm:FormGroup;
  constructor(private formbuilder: FormBuilder,) {
    this.userForm = this.formbuilder.group({
      name: ['', Validators.required],
      age:  ['', [Validators.required,Validators.min(15),Validators.max(100)]],
      place:  ['', Validators.required],
      password:['', [Validators.required,Validators.minLength(6)]],
      cpassword:['', Validators.required],
    });
   }
 
  
 
  ngOnInit(): void {
    this.userForm.valueChanges.subscribe(frm => {
      const password = frm.password;
      const confirm = frm.cpassword;
      if (password !== confirm) {
        this.userForm.get('cpassword')?.setErrors({ notMatched: true });
      }
      else {
        this.userForm.get('cpassword')?.setErrors(null);
      }
    });
   
  }
  

  

}
