import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl:any;

  constructor(private http: HttpClient,) { 
    this.baseUrl= 'https://api.sharecheck.in';
  }

  
  sendOtp(phone:any,captcha:any) {
    // const formdata= new FormData();
    // formdata.append("phoneNumber",phone)
    // formdata.append("recaptchaToken",captcha)
    let temp={
      "phoneNumber": phone,
      "recaptchaToken": captcha
     }

    return this.http.post(this.baseUrl + '/v1/auth/sendtoken/',temp);
  }
  verifyOtp(session:any,code:any) {
    
    let temp={
      "sessionInfo":session,
      "code":code
    }
    return this.http.post(this.baseUrl + '/v1/auth/verifytoken/',temp);
  }

  getToken() {
    return !!localStorage.getItem("TOKEN");
  }

  getTokendata() {
    return localStorage.getItem("TOKEN");
  }



}
