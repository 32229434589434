<div class="container my-5">

    <div class="icon-head">
        <h4>Almost there..</h4>
        <img src="assets/logo/icon.png" alt="" width="100px" height="auto">
    </div>

    <form [formGroup]="userForm" class="user-form">



        <div class="form-group ">

            <input class="form-control" type="text" formControlName="name" placeholder="Name">

            <div  *ngIf="userForm.get('name')?.invalid && (userForm.get('name')?.dirty || userForm.get('name')?.touched)">
                <div *ngIf="userForm.get('name')?.errors?.required" class="error-text">
                    Name is required.
                </div>
            </div>

        </div>
        <div class="form-group">

            <input class="form-control" type="number" formControlName="age" placeholder="Age" maxlength="2">

            <div  *ngIf="userForm.get('age')?.invalid && (userForm.get('age')?.dirty || userForm.get('age')?.touched)">
                <div *ngIf="userForm.get('age')?.errors?.required" class="error-text">
                    Age is required.
                </div>
                <div *ngIf="userForm.get('age')?.errors?.max" class="error-text">
                    max age should be  100.

                </div>
                <div *ngIf="userForm.get('age')?.errors?.min" class="error-text">
                    min age should be  15.

                </div>
            </div>

        </div>
        <div class="form-group">

            <input class="form-control" type="text" formControlName="place" placeholder="Place">

            <div  *ngIf="userForm.get('place')?.invalid && (userForm.get('place')?.dirty || userForm.get('place')?.touched)">
                <div *ngIf="userForm.get('place')?.errors?.required" class="error-text">
                    Place is required.
                </div>
            </div>

        </div>
        <div class="form-group">

            <input class="form-control" type="password" formControlName="password" placeholder="Create password">

            <div  *ngIf="userForm.get('password')?.invalid && (userForm.get('password')?.dirty || userForm.get('password')?.touched)">
                <div *ngIf="userForm.get('password')?.errors?.required" class="error-text">
                    password is required.
                    
                </div>
                <div *ngIf="userForm.get('password')?.errors?.minlength" class="error-text">
                    Password length must be 6 characters

                </div>
            </div>
        </div>
        <div class="form-group">

            <input class="form-control" type="password" formControlName="cpassword" placeholder="Confirm password">

            <div  *ngIf="userForm.get('cpassword')?.invalid && (userForm.get('cpassword')?.dirty || userForm.get('cpassword')?.touched)">
               
                <div *ngIf="userForm.get('cpassword')?.hasError('notMatched') " class="error-text">
                    Password not matched.
                </div>
            </div>

        </div>
        <div class="form-group">

            <input class="btn-finish" type="submit" [disabled]="userForm.invalid" value="Finish">
        </div>



    </form>

</div>