import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './service/api.service';

ApiService
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private service: ApiService, private router: Router) {}
  
  canActivate():boolean {
    if (this.service.getToken()) {
      return true;
    }
    else {
      this.router.navigate(['/resgiter']);
      return false;
    }
  }
  
}
