import { AfterContentChecked, ViewChild, AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WindowService } from '../service/window.service';

import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { environment } from 'src/environments/environment';
import { ApiService } from '../service/api.service';
import { trigger, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-register',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit, AfterContentChecked {

  windowRef: any;
  countrycode: string = '+91';
  phone: string = '';
  phoneNumber: any;
  otp: any;
  is_otp: boolean = false;
  sessionInfo: any;
  captcha: any;
  isNewUser: any;
  token: any;
  retoken: any;
  loading: boolean = false;
  verifying: boolean = false;
  activeCaptcha: boolean= false;
  validNumber: boolean= false;
  otpFilled: boolean = false;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };
  
  
  

  constructor(public fauth: AngularFireAuth, private windowService: WindowService,
    private api: ApiService, private router: Router) {

    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    } else {
      firebase.app(); // if already initialized, use that one
    }

  }

  ngOnInit(): void {

    this.windowRef = this.windowService.windowRef;



  }

  onOtpChange(otp: any) {
    this.otp = otp;
    if(this.otp.length == 6){
      this.otpFilled = true;
    }
    else{
      this.otpFilled = false;
    }
    

  }

  ngAfterViewInit() {
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response: any) => {
        console.log("hello", response);
        this.captcha = response;
        this.activeCaptcha = true;
        
      }
    });
    this.windowRef.recaptchaVerifier.render();
  }

  ngAfterContentChecked() {


  }

  sendOtp() {
    this.phoneNumber = this.countrycode + this.phone;
    console.log(this.phoneNumber);
    this.loading = true;
    this.api.sendOtp(this.phoneNumber,this.captcha).subscribe((res:any)=>{

      console.log("send otp response :",res);

        this.sessionInfo = res['sessionInfo'];

        console.log("last :", this.sessionInfo);
        this.is_otp= true;
    this.loading = false;
        });

    this.is_otp= true;

    this.loading = true;
    this.fauth.signInWithPhoneNumber(this.phoneNumber,this.windowRef.recaptchaVerifier).then((confirmationResult) => {
      this.windowRef.confirmationResult = confirmationResult;
      console.log();

      
    });
    // setTimeout(() => {
    //   this.is_otp = true;
    // }, 2000)
  }

  checkMobileNumber(){

    
    if(this.phone.length == 10)
    {
      this.validNumber = true;
    }
    else{
      this.validNumber = false;
    }
  }

  verifyOTP() {
    this.verifying = true;
    this.api.verifyOtp(this.sessionInfo, this.otp).subscribe(
      (res: any) => {
        this.verifying = false;
        console.log("# :", res);
        this.isNewUser = res.isNewUser;
        this.token = res.idToken;
        this.retoken = res.refreshToken;
        localStorage.setItem("TOKEN", res.idToken);
        localStorage.setItem("RETOKEN", res.refreshToken);
        localStorage.setItem("usernum", this.phone);

        if (this.isNewUser) {
          this.router.navigate(['/userinfo'])
        }
        else {
          this.router.navigate(['/home'])
        }

      }
    )

    this.windowRef.confirmationResult.confirm(this.otp)
      .then((userCredentials: any) => console.log(userCredentials)).catch(
        function (error: any) {
          console.log("catch", error);

        });
  }



}


