<div class="container">
    <div class="logo">
        <img src="assets\logo\logo.png" width="200px" height="auto" alt="share check" >
    </div>
</div>

<div class="container login-form">

    <form >

        <div class="form-group">
            <input type="text" placeholder="Mobile number">
        </div>
        <div class="form-group">
            <input type="password" placeholder="Password">
        </div>
        <div class="forgot">
            forgot password?
        </div>
        <div class="form-group">
            <input type="submit" value="Sign In">
        </div>
        
    </form>


</div>

<div class="container footer">
    New to ShareCheck? &nbsp; <span class="sinup-txt"><a [routerLink]="['/register']">SignUp</a></span>
</div>