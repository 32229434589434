<div class="container"  >
    <div class="logo">
        <img src="assets\logo\logo.png" width="200px" height="auto" alt="share check">
    </div>
</div>
<div class="container">
    <div class="info-text">
        <p *ngIf="!is_otp" >ShareCheck will send an sms message to verify your phone
            number.
        </p>
        <p *ngIf="is_otp" >
            Enter Otp and verify your number
        </p>
    </div>
</div>

<div class="container login-form">



    <form (ngSubmit)="sendOtp()" *ngIf="!is_otp">

        <div class="form-group">
            <input type="text" (keyup)="checkMobileNumber()" name="phone"[(ngModel)]="phone" placeholder="Mobile Number" maxlength="10">
        </div>

        <div id="recaptcha-container"></div>

        <div class="form-group" style="margin-top: 10px;">
            <button class="sc-btn-primary" type="submit" [nbSpinner]="loading"
            nbSpinnerStatus="success"
            nbSpinnerSize="small"
            nbSpinnerMessage="Sending OTP ..." [disabled]="!activeCaptcha || !validNumber" > <span *ngIf="!loading">Get Otp</span> </button>
        </div>

    </form>

    <div *ngIf="is_otp"  [@fadeInOut]>
        <div class="form-group">
           

            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>

            <div class="form-group" style="margin-top: 10px;">
                <button class="sc-btn-primary" type="button" (click)="verifyOTP()"
                [nbSpinner]="verifying"
                nbSpinnerStatus="success"
                nbSpinnerSize="small"
                nbSpinnerMessage="Verifying OTP ..."
                [disabled]="!otpFilled"  >  <span *ngIf="!verifying" >Verify OTP</span>   </button>
            </div>
        </div>

    </div>

</div>

<div class="container footer">
    Am in ShareCheck &nbsp; <span class="sinup-txt"><a [routerLink]="['/login']">SignIn</a></span>
</div>